import * as React from 'react';
import VoiceActivityDetectionPage
  from "../../../ui/pages/partners/glaxf/VoiceActivityDetectionPage";

// markup
const Page = () => {
  return <VoiceActivityDetectionPage/>;
};

export default Page;
