import * as React from 'react';
import VoiceActivityDetection from 'voice-activity-detection'

const VoiceActivityDetectionPage = () => {
  console.log(`VoiceActivityDetection`, VoiceActivityDetection)

  return (
      <>
        <h1>hello</h1>
      </>
  )
};

export default VoiceActivityDetectionPage;
